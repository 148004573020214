.slide__main {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  height: 100vh; }

.main__content {
  display: flex;
  width: 100%;
  margin-bottom: 10%;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 1; }
  .slide__main--404 .main__content {
    justify-content: center;
    flex-wrap: wrap;
    flex-flow: column;
    align-items: center;
    margin-bottom: 35%; }
  @media screen and (max-width: 1024px) {
    .main__content {
      flex-wrap: wrap;
      margin-bottom: 35%; }
      .slide__main--404 .main__content {
        margin-bottom: 60%;
        justify-content: center; } }

.main__title {
  font-family: "IBM Plex Sans", sans-serif;
  max-width: 690px;
  font-size: 6.5rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #343d46;
  margin: 0; }
  .slide__main--404 .main__title {
    font-size: 2.8rem; }
  @media screen and (max-width: 1024px) {
    .main__title {
      font-size: 3.8rem; }
      .slide__main--404 .main__title {
        font-size: 2.8rem; } }
  @media screen and (max-width: 320px) {
    .main__title {
      font-size: 4rem; } }

.main__img {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(src/img/cry.svg);
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  transition: background-position 0.25s; }
  .slide__main--404 .main__img {
    top: -150px;
    transform: rotate(180deg); }
  @media screen and (max-width: 1024px) {
    .main__img {
      top: -100px;
      background-size: contain; } }
  @media screen and (max-width: 320px) {
    .main__img {
      top: -60px; } }
  .main__img img {
    width: 648px;
    height: 578px;
    object-fit: contain; }
    @media screen and (max-width: 1024px) {
      .main__img img {
        width: 100%;
        height: 100%; } }

.main__pattern {
  position: fixed;
  background-image: url(src/img/pattern-2.png);
  width: 60%;
  height: 100%;
  right: 2%;
  z-index: -1; }
  @media screen and (max-width: 1024px) {
    .main__pattern {
      width: 83%;
      right: 0; } }

.main__button-wrap {
  position: relative;
  margin: 0 0 40px; }
  @media screen and (max-width: 1024px) {
    .main__button-wrap {
      margin: 0 auto;
      padding-left: 140px;
      padding-top: 80px; } }

.main__button {
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #dd0a35;
  cursor: pointer; }
  .main__button::before {
    position: absolute;
    left: -87px;
    top: -30px;
    content: '';
    width: 75px;
    height: 75px;
    background-color: #dd0a35;
    cursor: pointer; }
  .main__button::after {
    position: absolute;
    left: -60px;
    top: 0px;
    content: '';
    width: 20px;
    height: 20px;
    object-fit: contain;
    cursor: pointer;
    background-image: url(src/img/arrow-down.svg); }

.title-404 {
  position: absolute;
  bottom: 46px;
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  font-family: "Open Sans", sans-serif; }

.services {
  position: relative;
  margin-bottom: 150px; }
  @media screen and (max-width: 1024px) {
    .services {
      margin-bottom: 100px; } }
  .services__title {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 46px;
    font-weight: 500;
    color: #343d46;
    margin-top: 120px;
    margin-bottom: 70px; }
    @media screen and (max-width: 1024px) {
      .services__title {
        margin-bottom: 25px; } }
  .services__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .services__item {
    position: relative;
    width: 33.33333333333333%;
    height: 300px;
    border: solid 1px #dfe2e6;
    padding: 25px 35px; }
    @media screen and (max-width: 1024px) {
      .services__item {
        padding: 15px;
        margin-bottom: -1px; }
        .services__item::after {
          content: '';
          position: absolute;
          right: 15px;
          top: 0;
          background: url(src/img/plus.svg) no-repeat center right;
          width: 100%;
          height: 100%;
          z-index: 1; } }
    @media screen and (max-width: 1024px) {
      .services__item {
        width: 100%;
        height: 100%; } }
    .services__item_main {
      font-family: "IBM Plex Sans", sans-serif;
      font-size: 4rem;
      font-weight: 500;
      color: #dd0a35;
      line-height: normal; }
      @media screen and (max-width: 1024px) {
        .services__item_main {
          font-size: 2.4rem; } }
    .services__item_hover {
      position: absolute;
      cursor: pointer;
      padding: 25px 35px;
      left: 0;
      top: 0;
      width: 100%;
      background-color: #dd0a35;
      color: #fff;
      z-index: 2;
      transition: all .2s ease-in-out;
      visibility: hidden; }
    .services__item_title {
      margin: 0;
      font-family: "IBM Plex Sans", sans-serif;
      font-size: 4rem;
      font-weight: 500;
      line-height: normal;
      color: #fff; }
    .services__item_text {
      margin: 30px 0 0;
      font-size: 1.4rem;
      line-height: 1.5;
      color: #fff; }
    .services__item ul {
      font-size: 1.4rem;
      line-height: 2.4rem; }
    .services__item:hover {
      transition: all .2s ease-in-out; }
      .services__item:hover .services__item_hover {
        visibility: visible; }
        @media screen and (max-width: 1024px) {
          .services__item:hover .services__item_hover {
            visibility: hidden; } }

.team {
  position: relative;
  padding-top: 30px;
  padding-bottom: 300px;
  background-color: #fff; }
  @media screen and (max-width: 1024px) {
    .team {
      padding-bottom: 30px; }
      .team .section {
        padding-right: 0 !important; } }
  .team h2 {
    margin-top: 120px; }
  .team h3 {
    margin-top: 0;
    margin-bottom: 50px;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.78;
    letter-spacing: normal;
    color: #343d46; }
  .team::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: url(src/img/shark.svg) no-repeat bottom left;
    width: 100%;
    height: 100%;
    z-index: 1; }
    @media screen and (max-width: 1024px) {
      .team::after {
        bottom: -50px;
        background-size: contain; } }
  .team__wrap {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
    @media screen and (max-width: 1024px) {
      .team__wrap {
        flex-wrap: nowrap;
        overflow-y: scroll; } }
  .team__item {
    width: 412px;
    height: 100%;
    box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    margin-bottom: 32px;
    margin-right: 32px;
    z-index: 2; }
    .team__item:last-child {
      margin-right: 0; }
    @media screen and (max-width: 1024px) {
      .team__item {
        width: 220px;
        margin-right: 30px; } }
    .team__item img {
      width: 100%;
      height: 412px; }
      @media screen and (max-width: 1024px) {
        .team__item img {
          width: 220px;
          height: 220px; } }
    .team__item:last-child {
      margin-right: 0; }
    .team__item_info {
      padding: 40px 30px;
      background-color: #fff; }
      .team__item_info_hover {
        position: absolute;
        cursor: pointer;
        padding: 25px 35px;
        left: 0;
        top: 0;
        width: 100%;
        min-height: 100%;
        background-color: #dd0a35;
        color: #fff;
        z-index: 2;
        transition: all .2s ease-in-out;
        visibility: hidden; }
        @media screen and (max-width: 1024px) {
          .team__item_info_hover {
            display: none; } }
        .team__item_info_hover p {
          margin: 12px 0 0;
          font-size: 1.4rem;
          line-height: 1.5;
          color: #fff; }
      .team__item_info_title {
        margin: 0 !important;
        font-family: "IBM Plex Sans", sans-serif;
        font-size: 4rem !important;
        font-weight: 500 !important;
        line-height: normal !important;
        color: #fff; }
    .team__item_wrap {
      position: relative; }
      .team__item_wrap:hover {
        transition: all .2s ease-in-out; }
        .team__item_wrap:hover .team__item_info_hover {
          visibility: visible; }
          @media screen and (max-width: 1024px) {
            .team__item_wrap:hover .team__item_info_hover {
              visibility: hidden; } }
    .team__item_position {
      opacity: .7;
      font-family: "Open Sans", sans-serif;
      font-size: 1.6rem;
      line-height: normal;
      color: #343d46;
      margin: 0 0 6px; }
    .team__item_name {
      font-family: "Open Sans", sans-serif;
      font-size: 2.4rem;
      line-height: normal;
      color: #343d46;
      margin: 0; }

header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding: 0 35px;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 3;
  top: 0; }
  @media screen and (max-width: 1024px) {
    header {
      height: 61px;
      padding: 0 15px; } }
  header::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: #dfe2e6; }
  header .logo__img {
    width: 210px;
    height: 55px;
    object-fit: contain; }
    @media screen and (max-width: 1024px) {
      header .logo__img {
        width: 150px; } }
  header .menu {
    display: flex;
    height: 100%;
    align-items: center; }
    @media screen and (max-width: 1024px) {
      header .menu {
        display: none; } }
    header .menu a {
      padding: 18px;
      opacity: 0.8;
      font-family: "Open Sans", sans-serif;
      font-size: 1.6rem;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #343d46;
      text-decoration: none; }
    header .menu__lang {
      color: #dd0a35 !important;
      padding-right: 30px !important; }
    header .menu .hide {
      display: none; }
    header .menu__phone {
      position: relative;
      padding: 0 0 0 30px;
      height: 100%;
      display: flex;
      align-items: center; }
      header .menu__phone span {
        font-size: 1.6rem; }
      header .menu__phone a {
        color: #dd0a35;
        padding: 0 0 0 10px; }
      header .menu__phone::after {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #dfe2e6; }

.slide__about {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  padding-top: 100px; }
  @media screen and (max-width: 1024px) {
    .slide__about {
      flex-wrap: wrap;
      padding-top: 50px; } }
  .slide__about-text {
    width: 47%;
    padding-right: 3%; }
    @media screen and (max-width: 1024px) {
      .slide__about-text {
        width: 100%; } }
    .slide__about-text h2 {
      margin-bottom: 24px; }
      @media screen and (max-width: 1024px) {
        .slide__about-text h2 {
          font-size: 3.6rem; } }
    .slide__about-text h3 {
      margin-bottom: 14px;
      margin-top: 36px; }
  .slide__about-fact {
    display: flex;
    flex-flow: wrap;
    align-content: flex-start;
    width: 50%;
    margin-top: 50px; }
    @media screen and (max-width: 1024px) {
      .slide__about-fact {
        width: 100%; } }
    .slide__about-fact-info {
      display: flex;
      padding: 50px;
      flex-flow: column;
      width: 50%;
      border: solid 1px #dfe2e6;
      height: 50%; }
      @media screen and (max-width: 1024px) {
        .slide__about-fact-info {
          padding: 10px;
          border: 0; } }
      .slide__about-fact-info span:first-child {
        display: block;
        font-size: 6.5rem;
        font-weight: 500;
        color: #dd0a35;
        font-family: "IBM Plex Sans", sans-serif; }
        @media screen and (max-width: 1024px) {
          .slide__about-fact-info span:first-child {
            font-size: 5.5rem; } }
      .slide__about-fact-info span:last-child {
        display: block;
        font-size: 1.6rem;
        line-height: 1.56;
        color: #dd0a35; }

.about {
  position: relative;
  background-color: #fff; }
  .about__facts {
    position: relative;
    height: 100%;
    width: 100%;
    padding-bottom: 100px;
    z-index: 2; }
    @media screen and (max-width: 1024px) {
      .about__facts {
        padding-bottom: 0; } }
    .about__facts::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      background: url(src/img/group-41.svg) no-repeat right top;
      width: 100%;
      height: 100%;
      max-width: 411px;
      max-height: 411px;
      z-index: 1; }
      @media screen and (max-width: 1024px) {
        .about__facts::after {
          display: none; } }
  .about::after {
    position: absolute;
    content: '';
    background-image: url(src/img/pattern-1.png);
    width: 40%;
    height: 224px;
    right: 0;
    bottom: 0;
    z-index: 1; }
    @media screen and (max-width: 1024px) {
      .about::after {
        display: none; } }

@media screen and (max-width: 1024px) {
  #contacts.section {
    padding: 0 !important; } }

.contacts {
  display: flex;
  margin-top: 100px;
  z-index: 2; }
  .contacts__desktop {
    display: block; }
  .contacts__mobile {
    display: none; }
    .contacts__mobile-title {
      display: flex;
      align-items: center;
      height: 80px; }
      .contacts__mobile-title span {
        font-size: 2.1rem;
        color: #dd0a35;
        padding: 0 25px;
        text-align: center;
        width: 100%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 81px; }
      .contacts__mobile-title span.active {
        color: #fff;
        background-color: #dd0a35; }
    .contacts__mobile-text {
      position: relative; }
      .contacts__mobile-text div {
        padding: 15px;
        width: 100%;
        background-color: #dd0a35;
        display: none; }
        .contacts__mobile-text div p {
          font-size: 1.8rem;
          line-height: 1.67;
          opacity: 0.7;
          color: #fff; }
      .contacts__mobile-text div.active {
        display: block; }
  @media screen and (max-width: 1024px) {
    .contacts {
      flex-wrap: wrap;
      margin-top: 0; }
      .contacts__desktop {
        display: none; }
      .contacts__mobile {
        display: block; } }
  .contacts__info {
    position: relative;
    width: 54%; }
    @media screen and (max-width: 1024px) {
      .contacts__info {
        width: 100%; } }
    .contacts__info::after {
      position: absolute;
      content: '';
      background-image: url(src/img/pattern-3.png);
      width: 150%;
      height: 306px;
      left: -55%;
      top: -126px;
      z-index: -1; }
      @media screen and (max-width: 1024px) {
        .contacts__info::after {
          top: -30px; } }
    .contacts__info h2 {
      font-family: "IBM Plex Sans", sans-serif;
      font-size: 46px;
      font-weight: 500;
      color: #343d46;
      margin-bottom: 74px;
      margin-left: 70px; }
      @media screen and (max-width: 1024px) {
        .contacts__info h2 {
          margin-left: 15px;
          margin-bottom: 32px; } }
  .contacts__map {
    width: 46%;
    min-height: 100%; }
    @media screen and (max-width: 1024px) {
      .contacts__map {
        width: 100%; } }
    .contacts__map-moscow, .contacts__map-piter {
      display: none; }
    .contacts__map-active {
      display: block;
      height: 100%; }
  .contacts__block {
    position: relative;
    width: 100%;
    padding: 70px; }
    @media screen and (max-width: 1024px) {
      .contacts__block {
        padding: 10px; } }
    .contacts__block:hover {
      cursor: pointer;
      background-color: #dfe2e6; }
    .contacts__block h4 {
      font-family: "Open Sans", sans-serif;
      font-size: 32px;
      margin: 0 0 20px;
      color: #dd0a35; }
    .contacts__block p {
      opacity: 0.7;
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      line-height: 1.5;
      color: #343d46;
      margin: 0; }
    .contacts__block-active {
      color: #fff;
      background-color: #dd0a35 !important;
      height: 100%; }
      .contacts__block-active p,
      .contacts__block-active h4 {
        color: #fff; }
      .contacts__block-active::after {
        position: absolute;
        right: 30px;
        top: 48%;
        transform: rotate(-90deg);
        content: '';
        width: 21px;
        height: 21px;
        object-fit: contain;
        cursor: pointer;
        background-image: url(src/img/arrow-down.svg);
        background-repeat: no-repeat; }
  .contacts iframe {
    filter: grayscale(100%);
    height: 100%; }
    @media screen and (max-width: 1024px) {
      .contacts iframe {
        height: 375px; } }

.copyright {
  display: flex;
  justify-content: center;
  margin: 40px 40px 20px; }
  @media screen and (max-width: 1024px) {
    .copyright {
      display: none; } }
  .copyright a,
  .copyright span {
    font-size: 16px;
    color: #343d46;
    text-decoration: none; }
  .copyright span {
    display: block;
    margin: 22px; }

.partners {
  position: relative;
  display: flex;
  margin-bottom: 200px; }
  @media screen and (max-width: 1024px) {
    .partners {
      flex-wrap: wrap;
      margin-bottom: 40px; } }
  .partners__left {
    width: 300px; }
    @media screen and (max-width: 1024px) {
      .partners__left {
        width: 100%; } }
    .partners__left::after {
      position: absolute;
      content: '';
      background-image: url(src/img/pattern-1.png);
      width: 336px;
      height: 224px;
      left: -65px;
      top: 50px;
      z-index: -1; }
      @media screen and (max-width: 1024px) {
        .partners__left::after {
          display: none; } }
  .partners__right {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center; }
    @media screen and (max-width: 1024px) {
      .partners__right {
        display: none; } }
  .partners__item {
    width: 25%;
    padding: 25px 40px;
    text-align: center;
    cursor: pointer; }
    @media screen and (max-width: 1024px) {
      .partners__item {
        width: 100%;
        padding: 15px 20px; } }
    .partners__item img {
      width: 100%;
      max-height: 80px;
      object-fit: contain;
      filter: grayscale(100%); }
      @media screen and (max-width: 1024px) {
        .partners__item img {
          width: 200px; } }
    .partners__item:hover img {
      filter: none; }

.siema {
  display: none; }
  @media screen and (max-width: 1024px) {
    .siema {
      display: block;
      height: 80px; } }
  .siema img {
    max-width: 150px;
    max-height: 80px;
    filter: grayscale(100%);
    display: none; }
    @media screen and (max-width: 1024px) {
      .siema img {
        display: block; } }
  .siema div:first-child {
    display: flex;
    align-items: center; }

html {
  font-size: 10px; }

div,
img,
p,
span {
  box-sizing: border-box;
  color: #343d46;
  font-family: "Open Sans", sans-serif; }

p {
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal; }

h2 {
  font-size: 4.6rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: "IBM Plex Sans", sans-serif; }

h3 {
  font-family: "Open Sans", sans-serif;
  font-size: 2.2rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal; }

ul {
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  list-style-image: url(src/img/shape.svg);
  padding: 0 0 0 25px; }
  ul.white {
    list-style-image: url(src/img/shape-white.svg); }
  ul li {
    margin-bottom: 20px; }

body {
  margin: 0;
  padding: 0;
  color: blue;
  background-color: #fff; }

a {
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #dd0a35; }

.fullpage-wrapper {
  max-width: 1370px;
  margin: 0 auto !important; }

.section {
  position: relative;
  max-width: 1370px;
  padding: 0 35px !important;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%; }
  @media screen and (max-width: 1024px) {
    .section {
      padding: 0 15px !important; } }

#fullpage {
  position: relative;
  display: flex;
  flex-flow: column;
  transition: all .3s ease-in;
  z-index: 2;
  background-color: #fff; }

#fullpage.content__hide {
  opacity: 0; }

#burgerBtn {
  border-top: 2px solid #343d46;
  height: 25px;
  width: 30px;
  box-sizing: border-box;
  position: absolute;
  z-index: 30;
  right: 20px;
  top: 15px;
  cursor: pointer;
  transition: all .3s ease-in; }
  #burgerBtn:before {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 30px;
    left: 0;
    background: #343d46;
    top: 10px;
    transition: all .3s ease-in; }
  #burgerBtn:after {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 30px;
    left: 0;
    background: #343d46;
    bottom: 0;
    transition: all .3s ease-in; }

#nav {
  background-color: #fff;
  position: absolute;
  top: 61px;
  left: 0;
  margin: 0;
  width: 100%;
  height: 0;
  padding: 0;
  z-index: 1;
  opacity: 0;
  transition: all .3s ease-in;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  visibility: hidden; }

.menu__show #nav {
  opacity: 1;
  height: 100vh;
  visibility: visible; }

@media screen and (min-width: 1024px) {
  .menu__mobile {
    display: none; } }

.menu__mobile-item {
  display: block;
  width: 100%;
  padding: 20px;
  opacity: 0.8;
  font-size: 18px;
  color: #343d46;
  text-decoration: none; }

.menu__mobile-phone {
  position: relative;
  text-align: center;
  font-size: 18px;
  width: 100%;
  margin-bottom: 32px; }
  .menu__mobile-phone span {
    font-family: "Open Sans", sans-serif;
    color: #343d46; }
  .menu__mobile-phone a {
    font-family: "Open Sans", sans-serif;
    display: initial;
    color: #dd0a35;
    font-size: 18px;
    text-decoration: none; }

.menu__mobile-copyright {
  border-top: 1px solid #dfe2e6;
  height: 57px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 0 15px;
  font-size: 14px;
  color: #343d46; }
  .menu__mobile-copyright a {
    color: #343d46;
    text-decoration: none; }

.menu__show #burgerBtn {
  border-color: transparent; }
  .menu__show #burgerBtn:before {
    transform: rotate(45deg);
    width: 33px;
    left: -2px; }
  .menu__show #burgerBtn:after {
    transform: rotate(135deg);
    bottom: 11px;
    width: 33px;
    left: -2px; }

#popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 50;
  transition: all .3s ease-in; }
  #popup .popup__content {
    position: relative;
    padding: 20px;
    background-color: #dd0a35;
    height: calc(100% - 64px);
    color: white;
    z-index: 99;
    margin: 32px 15px;
    overflow: auto; }
    #popup .popup__content p,
    #popup .popup__content ul {
      color: white;
      font-size: 1.6rem; }
    #popup .popup__content .services__item_title {
      font-size: 4.2rem; }
  #popup.popup__show {
    visibility: visible; }
  #popup .popup__close {
    position: absolute;
    right: 10px;
    top: 10px;
    background: url(src/img/x.svg) no-repeat center center;
    width: 12px;
    height: 12px;
    padding: 10px; }
  #popup::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0.7;
    background-color: #343d46; }

.disable__scroll {
  overflow: hidden; }

.mobile-phone {
  position: fixed;
  width: 60px;
  height: 60px;
  display: none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 2;
  right: 15px;
  bottom: 15px; }
  @media screen and (max-width: 1024px) {
    .mobile-phone {
      display: block; } }
  .mobile-phone::after {
    position: absolute;
    left: 20px;
    top: 20px;
    content: '';
    width: 22px;
    height: 22px;
    object-fit: contain;
    cursor: pointer;
    background-image: url(src/img/phone.svg);
    background-repeat: no-repeat; }

.mobile-lang {
  position: absolute;
  right: 50px;
  display: none; }
  @media screen and (max-width: 1024px) {
    .mobile-lang {
      display: block; } }
  .mobile-lang a {
    font-size: 18px;
    color: #dd0a35;
    text-decoration: none; }
  .mobile-lang .hide {
    display: none; }

.button {
  background-color: #dd0a35;
  padding: 25px 40px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  text-decoration: none; }
